"use strict";

import Accessibility from './modules/base/Accessibility';
import Listeners from './modules/base/Listeners';
import Page from './modules/base/Page';
import Header from './modules/components/header/Header';
import ProductDisplay from './modules/components/product/ProductDisplay';
import Jifiti from './modules/utilities/Jifiti'
import PromoCodeInterface from './modules/components/cart/PromoCodeInterface';

window.app_listeners = null;
window.app_page = null;
window.app_accessibility = null;
window.app_header = null;
window.product_display = null;
window.jifiti = null;
window.promo_code_interface = null;

document.addEventListener('DOMContentLoaded', () => {
  // Stored
  try {window.app_listeners = new Listeners} catch (e){console.error('[Listeners] module error: ', e)};
  try {window.app_page = new Page} catch (e){console.error('[Page] module error: ', e)};
  try {window.app_accessibility = new Accessibility} catch (e){console.error('[Accessibility] module error: ', e)};
  try {window.app_header = new Header} catch (e){console.error('[Header] module error: ', e)};
  try {window.product_display = new ProductDisplay} catch (e){console.error('[ProductDisplay] module error: ', e)};
  try {window.jifiti = new Jifiti} catch (e){console.error('[Jifiti] module error: ', e)};
  try {window.promo_code_interface = new PromoCodeInterface} catch (e){console.error('[PromoCodeInterface] module error: ', e)};
  // Not stored
  // try {new ModuleName} catch (e){console.error('[ModuleName] module error: ', e)};
})
