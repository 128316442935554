"use strict";

import "../../../../compiler/node_modules/regenerator-runtime/runtime";

class Utility {
  static applyToAll(string, fn, root_el = document, params = null) {
    let items;
    if (string instanceof NodeList) {
      items = string;
    } else {
      items = root_el.querySelectorAll(string);
    }
    if (items && items.length !== 0) {
      items.forEach(function (el) {
        if (params) {
          fn(el, params);
        } else {
          fn(el);
        }
      });
    }
  }

  static getOrdinal(nr) {
    const ordinalSuffixes = {
      "one": "st",
      "two": "nd",
      "few": "rd",
      "other": "th"
    };
    const ordinalPluralRules = new Intl.PluralRules("en", { type: "ordinal" });
    const ordinal = ordinalPluralRules.select(nr);
    return ordinalSuffixes[ordinal]
  }

  static keydownHandlerLast(e) {
    const evt = e || window.event;
    const stringTo = e.target.hasAttribute("data-tab-forwards")
      ? e.target.getAttribute("data-tab-forwards")
      : false;
    if (!stringTo) return;
    const elTo = document.querySelector(stringTo);
    if (!elTo) return;
    const keyCode = evt.which || evt.keyCode;
    if (keyCode === 9 && !evt.shiftKey) {
      // TAB pressed
      if (evt.preventDefault) evt.preventDefault();
      else evt.returnValue = false;
      elTo.focus();
    }
  }

  static keydownHandlerFirst(e) {
    const evt = e || window.event;
    const stringTo = e.target.hasAttribute("data-tab-backwards")
      ? e.target.getAttribute("data-tab-backwards")
      : false;
    if (!stringTo) return;
    const elTo = document.querySelector(stringTo);
    if (!elTo) return;
    const keyCode = evt.which || evt.keyCode;
    if (keyCode === 9 && evt.shiftKey) {
      // TAB+SHIFT pressed
      if (evt.preventDefault) evt.preventDefault();
      else evt.returnValue = false;
      elTo.focus();
    }
  }

  static trapFocus(firstString, lastString, trap = true) {
    const firstElement = document.querySelector(firstString);
    const lastElement = document.querySelector(lastString);
    if (!firstElement || !lastElement) return;

    if (trap) {
      lastElement.setAttribute("data-tab-forwards", firstString);
      lastElement.addEventListener("keydown", this.keydownHandlerLast, false);
      firstElement.setAttribute("data-tab-backwards", lastString);
      firstElement.addEventListener("keydown", this.keydownHandlerFirst, false);
    } else {
      lastElement.removeEventListener("keydown", this.keydownHandlerLast, false);
      lastElement.removeAttribute("data-tab-forwards");
      firstElement.removeEventListener("keydown", this.keydownHandlerFirst, false);
      firstElement.removeAttribute("data-tab-backwards");
    }
  }


  static getClientWidth() {
    return (this.clientWidth =
      window.outerWidth ||
      document.documentElement.clientWidth ||
      document.getElementsByTagName("body")[0].clientWidth);
  }

  static getClientHeight() {
    return (this.clientHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName("body")[0].clientHeight);
  }

  static loadImages(root = document) {
    const imgs = root.querySelectorAll('.lazy-load-active:not(.--loaded, .--loading)');
    if (!imgs || imgs.length == 0) return;
    imgs.forEach(el => {
      let img = null;
      if (el.tagName == 'IMG') {
        img = el;
      } else {
        img = el.querySelector('img');
      }
      if (!img) return
      img.src = img.getAttribute('data-src');
      el.classList.add('--loading');
      img.addEventListener('load', () => {
        el.classList.remove('--loading');
        el.classList.add('--loaded');
      })
    });
  }

  static getScrollBarWidth() {
    return window.innerWidth - document.documentElement.clientWidth
  }

  static addResizeObserver(el, fn = null, cssVarName = '--js-height') {
    function resizeHandler(not_initial = true) {
      if (cssVarName && cssVarName != '') {
        if (not_initial) {
          el.classList.add('--transitioning');
          el.addEventListener('transitionend', () => {
            el.classList.remove('--transitioning')
          }, { once: true })
        }
        el.style.setProperty(cssVarName, el.firstElementChild.getBoundingClientRect().height + 'px');
      }
      if (fn) {
        fn(el.firstElementChild.getBoundingClientRect().height);
      }
    }
    resizeHandler(false);
    let observer = null;
    try {
      observer = new ResizeObserver(resizeHandler);
      observer.observe(el.firstElementChild);
    } catch (e) { }
    return observer;
  }

  static inInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  static addChildrenObserver(el, fn = null) {
    if (fn) {
      try {
        const observer = new MutationObserver(fn);
        observer.observe(el, { childList: true, subtree: true });
      } catch (e) { }
    }
  }

  static getRootCssVar(key, type = 'string') {
    let value = getComputedStyle(document.documentElement).getPropertyValue(
      key
    );
    if (type == 'number') {
      if (value.includes('rem')) {
        value = 16 * Number(value.replace('rem', ''));
      } else if (value.includes('px')) {
        value = Number(value.replace('px', ''));
      }
    }
    return value;
  }

  static isHidden = el => {
    return el.clientHeight == 0;
  }

  static fixDuplicates(el) {
    if (el.id == '') return
    const check = document.querySelector(`#${el.id}`);
    if (!check) return
    if (check != el) {
      const number = check.id.replace(/\D/g, "");
      if (isNaN(parseInt(number))) {
        el.id = `${el.id}-1`;
      } else {
        el.id = el.id.replace(number, parseInt(number) + 1);
      }
      this.fixDuplicates(el);
    }
  }

  static equalArrays(arr1, arr2) {
    if (!arr1 || !arr2) return false;
    if (arr1 === arr2) return true;
    if (arr1.length != arr2.length) return false;
    for (var i = 0, l = arr1.length; i < l; i++) {
      if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
        if (this.equalArrays(arr1[i], arr2[i]))
          return false;
      }
      else if (arr1[i] != arr2[i]) {
        return false;
      }
    }
    return true;
  }

  // Fetch Inject | Copyright (C) VHS <vhsdev@tutanota.com> | @license Zlib
  // Original Code at https://codeberg.org/vhs/fetch-inject/src/branch/trunk/fetch-inject.js

  static async fetchInject(inputs, promise) {

    const filterSourceMaps = (string) => {
      string = string.replace(/\/\/[#@]\s(source(?:Mapping)?URL)=\s*(\S+)/, '');
      return string
    }

    const injector = (resource, rootEl, resourceType, resolve) => {
      if (rootEl.querySelector(`${resourceType}[injected-src="${resource.input}"]`)) {
        resolve(resource);
        return;
      }
      const resourceEl = rootEl.createElement(resourceType);
      const firstEncounter = rootEl.getElementsByTagName(resourceType)[0];
      resourceEl.setAttribute('injected-src', resource.input);
      resourceEl.appendChild(rootEl.createTextNode(filterSourceMaps(resource.text)));
      resourceEl.onload = resolve(resource);
      firstEncounter ? firstEncounter.parentNode.insertBefore(resourceEl, firstEncounter) : rootEl.head.appendChild(resourceEl);
    }

    const resources = [];
    const deferreds = promise ? [].concat(promise) : [];
    const thenables = [];

    inputs.forEach((input) => {
      deferreds.push(
        fetch(input)
          .then((res) => [res.clone().text(), res.blob()])
          .then(async (promises) => {
            const [text, blob] = await Promise.all(promises);
            resources.push({ text, blob, input });
          })
      )
    });
    await Promise.all(deferreds);
    resources.forEach((resource) =>
      thenables.push({
        then: (resolve) => {
          const type = resource.blob.type.includes('text/css') ? 'style' : 'script';
          injector(resource, document, type, resolve);
        }
      })
    );

    return await Promise.all(thenables);
  }

  static injectResources(key, sources, callback) {
    if (window.app_page?.injectedResources?.has(key)) {
      callback();
    } else {
      this.fetchInject(sources).then(() => {
        window.app_page?.injectedResources?.set(key, sources);
        callback();
      });
    }
  }


  static getMemberNumber() {
    try {
      if (window.get_cookie && window.atob) {
        var customerCookie = window.get_cookie("customer");
        var customerCookieStage = window.get_cookie("customer_stage");
        var customerCookieValue =
          customerCookie !== "null" ? customerCookie : null;
        var customerCookieValueStage =
          customerCookieStage !== "null" ? customerCookieStage : null;
        var customerDataEncoded =
          customerCookieValue || customerCookieValueStage;
        if (customerDataEncoded) {
          var customerData = window.atob(customerDataEncoded);
          var memberNumber = null;
          if (customerData) {
            var memberNumberMatch = customerData.match(/memnum=([0-9]+)&/);
            if (memberNumberMatch) {
              memberNumber = memberNumberMatch[1];
            }
          }

          if (memberNumber) {
            return memberNumber;
          }
        }
      }
      return false;
    } catch (e) {
      // nothing
    }
  }

  static interpolateTemplate(template, map, return_string = false, clean = true){
    if (!template || !map || !(map instanceof Object)) return
    const fragment = template.content.cloneNode(true);
    let string = this.interpolateString(this.stringFromFragment(fragment), map, false, clean);
    return return_string ? string : this.fragmentFromString(string);
  }

  static interpolateString(string, map, format = false, clean = true){
    if (!string || !map || !(map instanceof Object)) return
    string = `${string}`
    for (const key in map) {
      string = string.replace(new RegExp(`{${key}}`, 'g'), map[key]);
    }
    string = string.replace(`"=""`, `"`);
    if (clean) {
      string = string.replace(new RegExp(`{(.*?)}`, 'g'), '');
    }
    if (format){
      string = string.replace( /  +/g, ' ' ).trim();
    }
    return string;
  }

  static fragmentFromString(strHTML) {
    const el = document.createElement('template');
    el.innerHTML = strHTML;
    return el.content;
  }

  static stringFromFragment(fragment) {
    const el = document.createElement('div');
    el.appendChild(fragment);
    return el.innerHTML;
  }

  static clearArray(arr) {
    return arr.filter((item) => item != '')
  }

  static updateContent(obj, root = document) {
    if (!root) return
    const tokens = root.querySelectorAll('[data-content-key]');
    tokens.forEach(token => {
      const key = token.getAttribute('data-content-key');
      if (!obj.hasOwnProperty(key)) return
      if (typeof obj[key] == "string") {
        token.innerHTML = obj[key];
        return
      }
      for (const contentKey in obj[key]) {
        if (contentKey == 'inner') {
          token.innerHTML = obj[key][contentKey];
        } else if (contentKey == 'addClass') {
          let list = obj[key][contentKey] instanceof Array ? obj[key][contentKey] : [obj[key][contentKey]];
          list = this.clearArray(list);
          if (list.length != 0) {
            token.classList.add(...list);
          }
        } else if (contentKey == 'removeClass') {
          let list = obj[key][contentKey] instanceof Array ? obj[key][contentKey] : [obj[key][contentKey]];
          list = this.clearArray(list);
          if (list.length != 0) {
            token.classList.remove(...list);
          }
        } else {
          if (obj[key][contentKey] === null) {
            token.removeAttribute(contentKey);
          } else {
            token.setAttribute(contentKey, obj[key][contentKey]);
          }
        }
      }
    })
  }
}
export default Utility;
