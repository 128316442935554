import Utility from "../../base/Utility";


class PromoCodeInterface {
  constructor() {
    this.cart_item_template = document.querySelector("#cart-item-template");
    if (!this.cart_item_template) return;
    this.el = document.querySelector("#promo-code-interface");
    if (this.el) {
      this.apply_button = this.el.querySelector("#apply-promo-code");
      this.apply_button.addEventListener(
        "click", this.applyPromoCode.bind(this)
      );
      this.initial_promo_configs = window.initialPromoConfigs;
    } else {
      this.initial_promo_configs = null;
    }
    this.sales_tax_cart = window.salestaxCart;
    this.formatted_cart_total = window.formattedCartTotal;
    this.formatted_original_cart_total = window.formattedOriginalCartTotal;
    this.subscription_merch_number = window.subscriptionMerchNumber;
    this.flow_type = window.flowType;
    this.initPromoCodes();
    if (this.flow_type == "join") {
      this.updateApplePayMembershipPrice();
    }
    this.updateOrderDetails();
    this.updateTotalPrice();
    this.updatePaymentMethods();
  }

  initPromoCodes() {
    this.applied_promo_codes = null;
    if (this.initial_promo_configs) {
      this.applied_promo_codes = this.initial_promo_configs.filter(
        (config) => {return config.status == "applied";}
      ).map(
        (config) => {return config.promo_code;}
      );
    }
    if (this.applied_promo_codes) {
      this.insertPromoCodeWidgets();
    }
  }

  updateApplePayMembershipPrice() {
    Utility.updateContent({
      "apple-pay-membership-price": this.sales_tax_cart[this.subscription_merch_number].formatted_total_price
    });
  }

  updateTotalPrice() {
    let total_price_text;
    if (this.formatted_original_cart_total) {
      total_price_text = `<span class="--strike-through">${this.formatted_original_cart_total}</span>&nbsp;${this.formatted_cart_total}`;
    } else {
      total_price_text = this.formatted_cart_total;
    }
    Utility.updateContent({"cart-items-total-price": total_price_text});
  }

  async applyPromoCode() {
    if (this.el.querySelector(".--loading")) return;
    let promo_code = this.el.querySelector("input[name=promo_code]").value;
    if (!promo_code.length) return;
    promo_code = promo_code.toLowerCase();

    this.hidePromoCodeErrorMessage();
    this.showApplyButtonSpinner();

    try {
      const request_data = new FormData();
      request_data.append("promo_code", promo_code);
      request_data.append("cart", JSON.stringify(this.sales_tax_cart));
      const request_config = {
        method: "POST",
        headers: {"Accept": "application/json"},
        body: request_data,
      };
      const apply_promo_code_response = await fetch(
        "/payment/apply-promo-code", request_config
      );
      const response = await apply_promo_code_response.json();
      if (response.status === "success") {
        if (this.applied_promo_codes) {
          this.applied_promo_codes.push(promo_code);
        } else {
            this.applied_promo_codes = [promo_code];
        }
        window.salestaxCart = response.data.cart;
        this.sales_tax_cart = window.salestaxCart;
        this.formatted_cart_total = response.data.formatted_cart_total;
        this.formatted_original_cart_total = response.data.formatted_original_cart_total;
        if (this.flow_type == "join") {
          this.updateApplePayMembershipPrice();
        }
        this.updateOrderDetails();
        this.updateTotalPrice();
        this.updatePaymentMethods();
        this.insertPromoCodeWidgets();
        this.showPromoCodeAppliedMessage();
        this.removePromoCodeValue();
      } else {
        this.showPromoCodeErrorMessage(
          response.data.has_displayable_message ?
          response.data.message :
          response.data.promo_config?.has_displayable_message ?
          response.data.promo_config.message :
          "We're sorry, this promo code cannot be applied."
        );
      }
      this.hideApplyButtonSpinner();
    } catch (e) {
      this.showPromoCodeErrorMessage(
        "We're sorry, this promo code cannot be applied."
      );
      this.hideApplyButtonSpinner();
    }
  }

  showApplyButtonSpinner() {
    this.apply_button.classList.add("--loading");
  }

  hideApplyButtonSpinner() {
    this.apply_button.classList.remove("--loading");
  }

  insertPromoCodeWidgets() {
    let container = this.el.querySelector(
      "#applied-promo-codes"
    );
    let widgets_content = "";
    const template = this.el.querySelector(
      "#promo-code-template"
    );
    this.applied_promo_codes.forEach((promo_code) => {
      const template_vars = {
        data_promo_code: promo_code,
        promo_code: promo_code.toUpperCase(),
      };
      widgets_content += Utility.interpolateTemplate(
        template, template_vars, true
      );
    });
    Utility.updateContent({
      "promo-code-widgets": widgets_content
    });
    container.querySelectorAll(".promo__remove").forEach(
      (removal_icon) => {
        removal_icon.addEventListener(
          "click",
          this.clearAppliedPromoCode.bind(this)
        );
      }
    );
  }

  showPromoCodeAppliedMessage() {
    let message_element = this.el.querySelector("#promo__message");
    message_element.textContent = "Code Applied!";
    message_element.setAttribute("aria-live","assertive");
    message_element.setAttribute("role","alert");
    message_element.style.display = "";
    message_element.classList.remove("--fade-out");
    setTimeout(() => {
      message_element.classList.add("--fade-out");
    }, 50);
    setTimeout(() => {
      message_element.setAttribute("aria-live","");
      message_element.setAttribute("role","");
      message_element.textContent = "";
      message_element.style.display = "none";
    }, 800);
  }

  showPromoCodeErrorMessage(message) {
    let error_element = this.el.querySelector("#promo__error");
    error_element.textContent = message;
    error_element.style.display = "";
    error_element.setAttribute("aria-live","assertive");
    error_element.setAttribute("role","alert");
  }

  hidePromoCodeErrorMessage() {
    this.el.querySelector("#promo__error").style.display = "none";
  }

  removePromoCodeValue() {
    this.el.querySelector("#promo_code_input").value = "";
  }

  async clearAppliedPromoCode(event) {
    let target_icon = event.currentTarget;
    event.preventDefault();
    if (this.el.querySelector(".--loading")) return;
    target_icon.classList.add("--loading");
    let promo_code = target_icon.parentNode.getAttribute("data-promo-code");
    try {
      const request_data = new FormData();
      request_data.append("promo_code", promo_code);
      request_data.append("cart", JSON.stringify(this.sales_tax_cart));
      const request_config = {
        method: "POST",
        headers: {"Accept": "application/json"},
        body: request_data,
      };
      const remove_promo_code_response = await fetch(
        "/payment/remove-promo-code", request_config
      );
      const response = await remove_promo_code_response.json();
      if (response.status === "success") {
        target_icon.parentNode.remove();
        window.salestaxCart = response.data.cart;
        this.sales_tax_cart = window.salestaxCart;
        this.formatted_cart_total = response.data.formatted_cart_total;
        this.formatted_original_cart_total = response.data.formatted_original_cart_total;
        this.initial_promo_configs = response.data.promo_configs;
        this.initPromoCodes();
        if (this.flow_type == "join") {
          this.updateApplePayMembershipPrice();
        }
        this.updateOrderDetails();
        this.updateTotalPrice();
        this.updatePaymentMethods();
      } else {
        target_icon.classList.remove("--loading");
        this.showPromoCodeErrorMessage(
          response.data.has_displayable_message ?
          response.data.message :
          `Error removing code ${promo_code.toUpperCase()}`
        );
      }
    } catch (e) {
      target_icon.classList.remove("--loading");
      this.showPromoCodeErrorMessage(
        `Error removing code ${promo_code.toUpperCase()}`
      );
    }
  }

  updatePaymentMethods() {
    let cart_total = 0;
    let payment_method_elements = [
      "pof_container_header",
      "pof_container",
      "fli-paypal",
      "applepay-container"
    ];
    let display_style = "";
    this.getCartItemIds().forEach(
      (item_id) => {
        cart_total += (
          this.sales_tax_cart[item_id].total_price || 0
        );
      }
    );
    if (!cart_total) {
      let credit_card_input = this.getPaymentMethodInput("credit");
      display_style = "none";
      if (window.is_applepay_paypal_pof) {
        credit_card_input?.click();
      } else {
        payment_method_elements = payment_method_elements.slice(2);
        let pof_input = this.getPaymentMethodInput("pof");
        if (!pof_input?.checked && !credit_card_input?.checked) {
          (pof_input || credit_card_input)?.click();
        }
      }
    }
    this.togglePaymentMethodElements(
      payment_method_elements,
      display_style
    );
    // used for 3dsecure and apple pay
    amount = cart_total.toString();
  }

  togglePaymentMethodElements(
    element_ids,
    display_style = ""
  ) {
    element_ids.forEach(
      (element_id) => {
        let element = document.querySelector(
          `#payment_options #${element_id}`
        );
        if (element) {
          element.style.display = display_style;
        }
      }
    );
  }

  getPaymentMethodInput(element_value) {
    return document.querySelector(
      `#payment_options input[name="payment_type"][value="${element_value}"]`
    );
  }

  updateOrderDetails() {
    let order_details_content = "";
    let item_images = {};
    this.getCartItemIds().forEach(
      (item_id, index) => {
        let item = this.sales_tax_cart[item_id];
        let template_vars = this.getDefaultCartItemTemplateVars(item, index);
        if (this.flow_type == "giftbasket") {
          template_vars.item_quantity_label = "Quantity:&nbsp;";
          template_vars.item_quantity = item.quantity;
          template_vars.item_price_label = "Price each:&nbsp;";
          template_vars.item_price = this.getItemPriceText(item);
          template_vars.item_total_price_label = "Cost:&nbsp;";
        } else if (
          this.flow_type == "join" &&
          typeof(item.tracking_id) == "undefined"
        ) {
          template_vars.item_quantity_info = ` x ${item.quantity}`;
        }
        if (item.is_gift_sub) {
          template_vars.item_asterisk_info = "*";
          item_images[`cart-item-image-${index}`] = {
            src: `${imghost}/product/siteassets/general/${item_id}/image.jpg`
          };
        } else if (typeof(item.tracking_id) == "undefined") {
          item_images[`cart-item-image-${index}`] = {
            src: `${imghost}/product/full/air/${item_id}/promo_large.jpg`
          };
        }
        order_details_content += Utility.interpolateTemplate(
          this.cart_item_template, template_vars, true
        );
      }
    );
    Utility.updateContent({
      "cart-items-summary": order_details_content
    });
    Utility.updateContent(item_images);
  }

  getCartItemIds() {
    let item_ids = Object.keys(this.sales_tax_cart);
    let item_id_to_move = null;
    if (this.flow_type == "join") {
      item_id_to_move = (
        this.subscription_merch_number.toString()
      );
    } else if (this.flow_type == "giftbasket") {
      item_ids.filter(
        (item_id) => {return this.sales_tax_cart[item_id].is_latest_advent;}
      ).forEach(
        (item_id) => {item_id_to_move = item_id;}
      );
    }
    if (item_id_to_move) {
      item_ids.splice(item_ids.indexOf(item_id_to_move), 1);
      item_ids.splice(0, 0, item_id_to_move);
    }
    return item_ids;
  }

  getDefaultCartItemTemplateVars(item, index) {
    return {
      item_description: item.item,
      item_quantity_info: "",
      item_index: index,
      item_asterisk_info: "",
      item_quantity_label: "",
      item_quantity: "",
      item_price_label: "",
      item_price: "",
      item_total_price_label: "",
      item_total_price: this.getItemPriceText(item, true),
    };
  }

  getItemPriceText(item, use_total = false) {
    let item_price_text;
    if (item.promo_code) {
      let discount_text = (
        item.percentage_off == 100 ? "free" : `${item.percentage_off}% off`
      );
      let adjusted_price = (
        use_total ?
        item.formatted_total_price :
        item.formatted_price
      );
      let slashed_price = (
        use_total ?
        item.formatted_original_total_price :
        item.formatted_original_price
      );
      item_price_text = `<span class="--strike-through">${slashed_price}</span> <span>${adjusted_price}</span> <span class="--discount-text">(${discount_text})`;
    } else {
      item_price_text = use_total ? item.formatted_total_price : item.formatted_price;
    }
    return item_price_text;
  }
}

export default PromoCodeInterface
